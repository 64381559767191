import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowDown,
  faArrowUp,
  faBars,
  faUserPlus,
  faTimes,
  faArrowRight,
  faChevronCircleLeft,
  faCheckCircle,
  faExclamationCircle,
  faChevronRight,
  faUpload,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faSave
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
library.add(
  faArrowUp,
  faArrowDown,
  faBars,
  faUserPlus,
  faArrowRight,
  faTimes,
  faCheckCircle,
  faExclamationCircle,
  faCheckCircle,
  faChevronRight,
  faChevronCircleLeft,
  faExclamationCircle,
  faCheckCircle,
  faCheck,
  faUpload,
  faChevronDown,
  faChevronLeft,
  faSave
);

const FONT_AWESOME_COMPONENT = 'font-awesome-icon';

// Register FontAwesome component globally
Vue.component(FONT_AWESOME_COMPONENT, FontAwesomeIcon);

// tslint:disable-next-line:no-var-requires
const { default: config } = require('buefy/src/utils/config') as {
  default: {
    defaultIconComponent: string;
    defaultIconPack: string;
  };
};

// Force Buefy to use vue-fontawesome to render icons
config.defaultIconComponent = FONT_AWESOME_COMPONENT;
config.defaultIconPack = 'fas';
