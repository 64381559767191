import i18n from '@/config/i18n';
import '@/config/register-service-worker';
import router from '@/config/router';
import '@/modules/core/font-awesome';
import App from '@/pages/app/app.vue';
import store from '@/store';
import Vue from 'vue';
import './theme/all.scss';
import '@/config/raygun';
import '@/config/moment-locales';
import VueTelInput from 'vue-tel-input';
// tslint:disable-next-line: no-unsafe-any
Vue.use(VueTelInput);
Vue.config.productionTip = false;
// @ts-ignore
window.app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
