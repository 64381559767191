import env from '@/config/env';
import { NavigationFailureType, Page } from '@/config/router';
import { ScreenSize, Theme } from '@/modules/core';
import { RequireVue } from '@/modules/core/requirevue';
import { app } from '@/store/app.store';
import { auth } from '@/store/auth.store';
import { Component, Vue } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import { Menuitem } from '@/entities/menuitem/menuitem';
import vueRouter from 'vue-router';
// tslint:disable-next-line:no-var-requires
const { theme } = require('../../../tailwind.config.js') as Theme;
// tslint:disable-next-line:no-var-requires
const { BButton } = require('buefy/src/components/button') as RequireVue;
// tslint:disable-next-line:no-var-requires
const { BIcon } = require('buefy/src/components/icon') as RequireVue;
// tslint:disable-next-line:no-var-requires
const { BSidebar } = require('buefy/src/components/sidebar') as RequireVue;
// tslint:disable-next-line:no-var-requires
const { BMenu } = require('buefy/src/components/menu') as RequireVue;
// tslint:disable-next-line:no-var-requires
const { BMenuItem } = require('buefy/src/components/menu') as RequireVue;
// tslint:disable-next-line:no-var-requires
const { BMenuList } = require('buefy/src/components/menu') as RequireVue;
const { isNavigationFailure } = vueRouter;
const { PRIVACY_URL } = env.brand;
const DISABLE_SIDEBAR_ANIM_TIMEOUT_MS = 1000;


const icon = env.icons;
@Component({
  components: {
    BButton,
    BIcon,
    BSidebar,
    BMenu,
    BMenuList,
    BMenuItem,
  },
  name: 'default',
})
class Default extends Vue {

  // --------------------------------------------------------------------------
  // Fields
  // --------------------------------------------------------------------------
  public readonly logo = env.brand.logo;
  public isOpen = true;
  public isHidden = true;
  public canSidebarAnimate = true;

  public readonly privacyPolicyurl = PRIVACY_URL;
  public readonly privacyPolicyIcon = icon.PRIVACY_POLICY;
  public readonly Page = Page;
  private readonly logoutIcon = icon.LOGOUT;
  public menu: Menuitem[] = [];
  public readonly isSameOrigin = true;
  // --------------------------------------------------------------------------
  // Constructor
  // --------------------------------------------------------------------------

  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // Accessors
  // --------------------------------------------------------------------------
  public get name() {
    return auth.user?.firstName;
  }

  public get isLoading() {
    return app.isLoadingBackgroundData;
  }

  private get isDesktop() {
    return app.isDesktop;
  }

  private get isMobile() {
    return app.isMobile;
  }

  // --------------------------------------------------------------------------
  // Methods
  // --------------------------------------------------------------------------
  public open() {
    this.isOpen = true;
    this.isHidden = true;
    this.resetSidebarAnimationPermission();
  }

  public close() {
    this.isOpen = false;
    this.isHidden = false;
  }

  // --------------------------------------------------------------------------
  // Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // Methods
  // --------------------------------------------------------------------------

  public async logout() {
    const { success } = await auth.logout();

    if (success) {
      auth.clearUser();
      app.clearPatientData();
      this.hideSidebar();
      await this.navigate('/login');
    }

  }

  public async navigate(path: string, params?: Dictionary<string>) {
    await this.$router.push({ path, params })
      .catch((e: Error) => {
        // If the router error was because we are navigating to a location that we are already at(duplicate navigation)
        // then we ignore it, else we throw the error like normal
        // tslint:disable-next-line: strict-boolean-expressions
        if (isNavigationFailure(e && e.name !== NavigationFailureType.Duplicated)) {
          throw e;
        }
      });
  }

  private resetSidebarAnimationPermission() {
    this.canSidebarAnimate = true;
    setTimeout(() => this.canSidebarAnimate = false, DISABLE_SIDEBAR_ANIM_TIMEOUT_MS);
  }

  public async handleAccessCodeClicked() {
    await this.navigate('/redeem');
  }

  public loadForScreen() {
    if (this.isDesktop) {
      this.openSideBar(true);
    } else {
      this.openSideBar(false);
    }
  }

  private openSideBar(status: boolean) {
    this.isOpen = status;
    this.isHidden = status;
  }

  private hideSidebar() {
    this.isOpen = false;
    this.isHidden = true;
  }

  public async navTo(path: string, target: string) {
    const _blank = '_blank';

    if (!this.isDesktop) {
      this.close();
    }

    if (target !== _blank) {
      await this.$router.push({ name: path })
        .catch((e: Error) => {
          // If the router error was because we are navigating to a location that we are already at(duplicate navigation)
          // then we ignore it, else we throw the error like normal
          // tslint:disable-next-line: strict-boolean-expressions
          if (isNavigationFailure(e && e.name !== NavigationFailureType.Duplicated)) {
            throw e;
          }
        });
    } else {
      window.open(path, '_blank');
    }
  }

  // --------------------------------------------------------------------------
  // Lifecycle Hooks
  // --------------------------------------------------------------------------
  public mounted() {
    this.menu = [
      {
        icon: icon.DASHBOARD,
        label: 'sidemenu.dashboard',
        subtext: '',
        hasBorder: true,
        path: Page.Home,
        target: '',
        testIdentifier: 'menuItemDashboard',
      },
      {
        icon: icon.PATIENT_DETAILS,
        label: 'sidemenu.profiles',
        subtext: '',
        hasBorder: false,
        path: Page.Profiles,
        target: '',
        testIdentifier: 'menuItemProfiles',

      },
      {
        icon: icon.DOCUMENTS,
        label: 'sidemenu.documents',
        subtext: 'sidemenu.uploads',
        hasBorder: false,
        path: Page.Documents,
        target: '',
        testIdentifier: 'menuItemDocuments',
      },
      {
        icon: icon.SMART_FORMS,
        label: 'sidemenu.smartForms',
        subtext: 'sidemenu.graphs',
        hasBorder: false,
        path: Page.SmartForms,
        target: '',
        testIdentifier: 'menuItemSmartForms',
      },
      {
        icon: icon.APPOINTMENTS,
        label: 'sidemenu.appointments',
        subtext: '',
        hasBorder: true,
        path: Page.Appointments,
        target: '',
        testIdentifier: 'menuItemAppointments',
      },
      {
        icon: icon.USER_ICON,
        label: 'sidemenu.account',
        subtext: '',
        hasBorder: false,
        path: Page.Account,
        target: '',
        testIdentifier: 'menuItemAccount',
      },
      {
        icon: icon.PRIVACY_POLICY,
        label: 'sidemenu.privacyPolicy',
        subtext: '',
        hasBorder: false,
        path: this.privacyPolicyurl,
        target: '_blank',
        testIdentifier: 'menuItemPrivacyPolicy',
      },
    ];

    // Attach event listeners for change in screen size
    const {loadForScreen} = this;
    app.addResizeListener(loadForScreen);

    this.resetSidebarAnimationPermission();
  }

  public activated() {
    this.loadForScreen();
  }

  private beforeDestroy() {
    const {loadForScreen} = this;
    app.removeResizeListener(loadForScreen);
  }

  private beforeRouteEnter() {
    //
  }
}

export { Default as default, Default, };

