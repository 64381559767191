// The name property on each gender object refrences a key in the gender setion of  the locale files
export const GENDERS = [
  {
    iGenderID: 1,
    sGender: 'F',
    name: 'gender.female',
  },
  {
    iGenderID: 2,
    sGender: 'M',
    name: 'gender.male',
  },
  {
    iGenderID: 3,
    sGender: 'TG',
    name: 'gender.transgender',
  },
  {
    iGenderID: 4,
    sGender: 'F2M',
    name: 'gender.femaleToMale',
  },
  {
    iGenderID: 5,
    sGender: 'M2F',
    name: 'gender.maleToFemale',
  },
  {
    iGenderID: 6,
    sGender: 'O',
    name: 'gender.other',
  },
  {
    iGenderID: 7,
    sGender: 'A',
    name: 'gender.agender',
  },
  {
    iGenderID: 8,
    sGender: 'B',
    name: 'gender.bigender',
  },
  {
    iGenderID: 9,
    sGender: 'I',
    name: 'gender.intersex',
  },
  {
    iGenderID: 10,
    sGender: 'G',
    name: 'gender.genderFluid',
  },
  {
    iGenderID: 11,
    sGender: 'GQ',
    name: 'gender.genderQueer',
  },
  {
    iGenderID: 12,
    sGender: 'NB',
    name: 'gender.nonBinary',
  },
  {
    iGenderID: 13,
    sGender: 'P',
    name: 'gender.pangender',
  },
  {
    iGenderID: 14,
    sGender: 'TM',
    name: 'gender.transMale',
  },
  {
    iGenderID: 15,
    sGender: 'TF',
    name: 'gender.transFemale',
  },
  {
    iGenderID: 16,
    sGender: 'TS',
    name: 'gender.twoSpirit',
  },
];
