import env from '@/config/env';
import { AssignmentResponse, Gender, Patient, PatientCalendar, PatientDocument, PatientForm, PatientPractitioner, PatientProfile } from '@/entities';
import { Country } from '@/entities/country';
import { Phone } from '@/entities/phone';
import { Cache, getMultiParamModule, Lookup, Serializable, StoreActionResult, ScreenSize } from '@/modules/core';
import { MultiParamAction, MultiParamMutation } from '@/modules/core/vuex';
import { appService } from '@/services/app.service';
import { appendFileSync } from 'fs';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import store from './index';

const { USER_SETTINGS_ID, MASTER_STORAGE_ID } = env.keys;
const { ANIMATIONS_ENABLED } = env.featureToggle;
const DISABLE_ANIMATIONS_KEY = 'disable-animations';
const IS_LOADING_DOM_EVENT = `${MASTER_STORAGE_ID}:isloading`;

// ----------------------------------------------------------------------------
// Module Types

type PatientDataset<T> = {
  patientId: string;
  value?: T;
};

@Module({
  name: 'app',
  dynamic: true,
  namespaced: true,
  store
})
class AppStore extends VuexModule {
  // ------------------------------------------------------------------------
  // Fields
  // ------------------------------------------------------------------------
  private static readonly _preferences = new Cache(USER_SETTINGS_ID);
  private _patient: Patient | null = null;
  private _profiles: Lookup<PatientProfile | undefined> = {};
  private _calendars: Lookup<PatientCalendar | undefined> = {};
  private _forms: Lookup<PatientForm[] | undefined> = {};
  private _documents: Lookup<PatientDocument[] | undefined> = {};
  private _patients: Patient[] = [];
  private _genders: Gender[] = [];
  private _phoneTypes: Phone[] = [];
  private _countries: Country[] = [];
  private _patientPractitioners: PatientPractitioner[] = [];
  private _isLoading = false;

  private _disableAnimations = ANIMATIONS_ENABLED ? (AppStore._preferences.get<boolean | undefined>(DISABLE_ANIMATIONS_KEY) ?? false) : true;
  private _isDesktop = false;
  private _isTablet = false;
  private _isMobile = false;

  // ------------------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------------------
  public get patient() {
    return this._patient;
  }

  public get profiles() {
    return this._profiles;
  }

  public get calendars() {
    return this._calendars;
  }

  public get forms() {
    return this._forms;
  }

  public get documents() {
    return this._documents;
  }

  public get patients() {
    return this._patients;
  }

  public get genders() {
    return this._genders;
  }

  public get phoneTypes() {
    return this._phoneTypes;
  }

  public get countries() {
    return this._countries;
  }
  public get patientPractitioners() {
    return this._patientPractitioners;
  }

  /**
   * Flag showing when the app is loading background data.
   */
  public get isLoadingBackgroundData() {
    return this._isLoading;
  }

  public get disableAnimations() {
    return this._disableAnimations;
  }

  public get isDesktop() {
    return this._isDesktop;
  }

  public get isTablet() {
    return this._isTablet;
  }

  public get isMobile() {
    return this._isMobile;
  }

  // --------------------------------------------------------------------------
  // Event Handlers
  // --------------------------------------------------------------------------
  private windowResizeListener!: () => void;

  // ------------------------------------------------------------------------
  // Actions
  // ------------------------------------------------------------------------
  @MultiParamAction()
  public async cancelAppointment(id: string, reason: string) {
    const results = await appService.cancelAppointment(id, reason);

    return results.success;
  }
  @MultiParamAction()
  public async getDocumentUrl(documentId: number, patientId: string) {
    const result = await appService.getDocumentUrl(documentId, patientId);

    return result;
  }

  @MultiParamAction()
  public async getProvincesByCountryId(countryId: number) {
    const { data } = await appService.getProvinces(countryId);

    return data;
  }

  @MultiParamAction()
  public async uploadDocument(fileDetails: FormData, file: File) {
    const { success } = await appService.uploadDocument(fileDetails, file);

    return {
      success
    };
  }


  @MultiParamAction()
  public async getDocumentsByPatientId(patientId: number) {
    return this.documents[patientId];
  }

  @MultiParamAction()
  public async loadDocumentsByPatientId(patientId: string) {
    const { success, data } = await appService.getDocuments(patientId);

    if (success) {
      this.setDocuments({ patientId, value: data });
    }
  }

  @MultiParamAction()
  public async updateProfile(profile: PatientProfile) {
    const result = await appService.updateProfile(profile);

    if (result.success) {
      this.setProfile({ patientId: profile.iPatientID, value: profile });
    }

    return result;
  }

  @MultiParamAction()
  public async loadCountries() {

    if (this.countries.length < 1) {
      const countries = await appService.getCountries();
      this.setCountries(countries.data ?? []);
    }
  }

  @MultiParamAction()
  public async loadGenders() {

    if (this.genders.length < 1) {
      const genders = await appService.getGenders();
      this.setGenders(genders);
    }
  }

  @MultiParamAction()
  public async loadPhoneTypes() {
    const phoneTypes = await appService.getPhoneTypes();
    this.setPhoneTypes(phoneTypes);
  }

  @MultiParamAction()
  public async loadPatients() {
    const { success, data } = await appService.getPatients();
    this.setPatients(data ?? []);

    return { success };
  }

  @MultiParamAction()
  public getPreference(key: string) {
    const { _preferences } = AppStore;

    return _preferences.get(key);
  }

  @MultiParamAction()
  public setPreference<T extends Serializable>(key: string, value: T) {
    const { _preferences } = AppStore;

    _preferences.set(key, value);
  }

  @MultiParamAction()
  public async getPractitionersbyPatients(patients: Patient[]) {
    const patientsIds = this.patients.map((o) => o.id);
    const { success, data } = await appService.getPractitioners(patientsIds);

    this.setPatientPractitioner(data ?? []);

    return { data };
  }

  @MultiParamAction()
  public async loadPatientData(patientId: string, bypassCache = false) {
    let result: StoreActionResult;

    const profileResult = await this.loadPatientProfile(patientId, bypassCache);
    const documentsResult = await this.loadPatientDocuments(patientId, bypassCache);
    const calendarResult = await this.loadPatientCalendar(patientId, bypassCache);
    const formsResult = await this.loadPatientForms(patientId, bypassCache);

    result = {
      success: (profileResult.success &&
                documentsResult.success &&
                calendarResult.success &&
                formsResult.success)
    };

    return result;
  }

  @MultiParamAction()
  public async loadPatientProfile(patientId: string, bypassCache = false) {
    let result: StoreActionResult;
    let successCount = 0;
    const SKIPPED = { success: false, data: undefined };
    const { profiles } = this;
    const successCountTarget = 4;

    // Lazy loader.
    const load = <R>(src: Lookup, delegate: () => R) =>
      bypassCache || !(patientId in src) ? delegate() : SKIPPED;

    const profile = await load(profiles, () => appService.getProfile(patientId));

    if (profile.success) {
      successCount++;
      this.setProfile({ patientId, value: profile.data });
    }

    result = {
      success: successCount === successCountTarget
    };

    return result;
  }

  @MultiParamAction()
  public async loadPatientDocuments(patientId: string, bypassCache = false) {
    let result: StoreActionResult;
    let successCount = 0;
    const SKIPPED = { success: false, data: undefined };
    const { documents } = this;
    const successCountTarget = 4;

    // Lazy loader.
    const load = <R>(src: Lookup, delegate: () => R) =>
      bypassCache || !(patientId in src) ? delegate() : SKIPPED;

    const doc = await load(documents, () => appService.getDocuments(patientId));

    if (doc.success) {
      successCount++;
      this.setDocuments({ patientId, value: doc.data });
    }

    result = {
      success: successCount === successCountTarget
    };

    return result;
  }

  @MultiParamAction()
  public async loadPatientCalendar(patientId: string, bypassCache = false) {
    let result: StoreActionResult;
    let successCount = 0;
    const SKIPPED = { success: false, data: undefined };
    const { calendars } = this;
    const successCountTarget = 4;

    // Lazy loader.
    const load = <R>(src: Lookup, delegate: () => R) =>
      bypassCache || !(patientId in src) ? delegate() : SKIPPED;

    const calendar = await load(calendars, () => appService.getCalendar(patientId));

    if (calendar.success) {
      successCount++;
      this.setCalendars({ patientId, value: calendar.data });
    }

    result = {
      success: successCount === successCountTarget
    };

    return result;
  }

  @MultiParamAction()
  public async loadPatientForms(patientId: string, bypassCache = false) {
    let result: StoreActionResult;
    let successCount = 0;
    const SKIPPED = { success: false, data: undefined };
    const { forms } = this;
    const successCountTarget = 4;

    // Lazy loader.
    const load = <R>(src: Lookup, delegate: () => R) =>
      bypassCache || !(patientId in src) ? delegate() : SKIPPED;

    const form = await load(forms, () => appService.getForms(patientId));

    if (form.success) {
      successCount++;
      this.setForms({ patientId, value: form.data });
    }

    result = {
      success: successCount === successCountTarget
    };

    return result;
  }

  @MultiParamAction()
  public async loadFormsByPatientId(patientId: string) {
    const form = await appService.getForms(patientId);

    if (form.success) {
      this.setForms({ patientId, value: form.data });

      return form.success;
    }
  }

  @MultiParamAction()
  public async getForm_smartforms2(formId: string, patientId: string) {
    const result = await appService.getForm_smartforms2(formId, patientId);

    return result;
  }

  @MultiParamAction()
  public async submitAssignmentResponse(patientId: string, assignmentId: string, response: AssignmentResponse) {
    const { success } = await appService.submitAssignmentResponse(patientId, assignmentId, response);

    return success;
  }

  @MultiParamAction()
  public async updateAssignmentResponse(patientId: string, assignmentId: string, response: AssignmentResponse) {
    const { success } = await appService.updateAssignmentResponse(patientId, assignmentId, response);

    return success;
  }

  @MultiParamAction()
  public addResizeListener(modeChangedCallback?: () => void) {
    // tslint:disable-next-line: no-void-expression
    this.windowResizeListener = () => {
      const oldMode = {
        isDesktop: this.isDesktop,
        isTablet: this.isTablet,
        isMobile: this.isMobile
      };
      const currentScreenSize = window.innerWidth;
      this.setIsDesktop(currentScreenSize >= ScreenSize.DESKTOP ? true : false);
      this.setIsMobile(currentScreenSize < ScreenSize.TABLET ? true : false);
      this.setIsTablet(!(this.isDesktop || this.isMobile));
      // If view mode changed, run callback function
      if (oldMode.isDesktop !== this.isDesktop ||
          oldMode.isTablet !== this.isTablet ||
          oldMode.isMobile !== this.isMobile
      ) {
        window.dispatchEvent(new CustomEvent('appViewModeChanged', {
          detail: {
            isDesktop: this.isDesktop,
            isTablet: this.isTablet,
            isMobile: this.isMobile
          }
        }));
      }
    };
    // Call function to trigger default behaviour before event actually occurs
    this.windowResizeListener();
    // Attach event listeners for change in screen size
    window.addEventListener('resize', this.windowResizeListener);
    // Add event listener for callback function if view mode changed
    if (modeChangedCallback !== undefined) {
      modeChangedCallback();
      window.addEventListener('appViewModeChanged', modeChangedCallback);
    }
  }

  @MultiParamAction()
  public removeResizeListener(modeChangedCallback?: () => void) {
    // tslint:disable-next-line: no-unbound-method
    window.removeEventListener('resize', this.windowResizeListener);
    if (modeChangedCallback !== undefined) {
      window.removeEventListener('appViewModeChanged', modeChangedCallback);
    }
  }

  // ------------------------------------------------------------------------
  // Mutations
  // ------------------------------------------------------------------------
  @MultiParamMutation
  private setGenders(value: Gender[]) {
    this._genders = value;
  }

  @MultiParamMutation
  private setPhoneTypes(value: Phone[]) {
    this._phoneTypes = value;
  }

  @MultiParamMutation
  private setPatient(value: Patient) {
    this._patient = value;
  }

  @MultiParamMutation
  private setPatients(value: Patient[]) {
    this._patients = value;
  }

  @MultiParamMutation
  private setProfile({ patientId, value }: PatientDataset<PatientProfile>) {
    const { _profiles } = this;

    this._profiles = { ..._profiles, [patientId]: value };
  }

  @MultiParamMutation
  private setCalendars({ patientId, value }: PatientDataset<PatientCalendar>) {
    const { _calendars } = this;

    this._calendars = { ..._calendars, [patientId]: value };
  }

  @MultiParamMutation
  private setDocuments({ patientId, value }: PatientDataset<PatientDocument[]>) {
    const { _documents } = this;
    this._documents = { ..._documents, [patientId]: value };
  }

  @MultiParamMutation
  private setPatientPractitioner(value: PatientPractitioner[]) {
    this._patientPractitioners = [...value];
  }

  @MultiParamMutation
  private setForms({ patientId, value }: PatientDataset<PatientForm[]>) {
    const { _forms } = this;

    this._forms = { ..._forms, [patientId]: value };
  }

  @MultiParamMutation
  private setCountries(value: Country[]) {
    this._countries = [...value];
  }

  @MultiParamMutation
  public setIsLoading(value: boolean) {
    if (this._isLoading !== value) {
      this._isLoading = value;

      const event = new CustomEvent(IS_LOADING_DOM_EVENT, {
        bubbles: true,
        detail: {
          value
        }
      });

      // Raise custom event to notify testing framework of
      // the app's loading state.
      document.dispatchEvent(event);
    }
  }

  @MultiParamMutation
  public setDisableAnimations(value: boolean) {
    this._disableAnimations = value;

    AppStore._preferences.set(DISABLE_ANIMATIONS_KEY, value);
  }

  @MultiParamMutation
  private setIsDesktop(value: boolean) {
    this._isDesktop = value;
  }

  @MultiParamMutation
  private setIsTablet(value: boolean) {
    this._isTablet = value;
  }

  @MultiParamMutation
  private setIsMobile(value: boolean) {
    this._isMobile = value;
  }

  @Mutation
  public clearPatientData() {
    this._forms = {};
    this._documents = {};
    this._calendars = {};
    this._profiles = {};
    this._patients = [];
  }
}

const app = getMultiParamModule(AppStore, store);

export {
  app
};

