import { Serializable } from './serializable';

// ----------------------------------------------------------------------------
// Module Vars
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Module Types
// ----------------------------------------------------------------------------

interface CacheStore {
  [key: string]: Serializable;
}

class Cache {
  private cache?: CacheStore;

  // --------------------------------------------------------------------------
  // Constructor
  // --------------------------------------------------------------------------
  public constructor(private readonly name: string) {
    //
  }

  // --------------------------------------------------------------------------
  // Accessors
  // --------------------------------------------------------------------------
  private get store(): CacheStore {
    const { name } = this;
    let { cache } = this;

    // Load and cache value if it's undefined.
    if (cache === undefined) {
      const json = localStorage.getItem(name);
      cache = json !== null ? JSON.parse(json) as CacheStore ?? {} : {};

      this.cache = cache;
    }

    return cache;
  }

  // --------------------------------------------------------------------------
  // Accessors
  // --------------------------------------------------------------------------
  public get<T = Serializable>(key: string): T {
    const { store } = this;

    return store[key] as unknown as T;
  }

  public set(key: string, value: Serializable) {
    const { store } = this;

    store[key] = value;

    this.save();
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
  private save() {
    const { cache, name } = this;
    const json = JSON.stringify(cache);

    localStorage.setItem(name, json);
  }

}

export {
  Cache,
};

