import { IS_DEV } from '@/config/env';
import { appService } from '@/services/app.service';
import { authService } from '@/services/auth.service';
import { app } from '@/store/app.store';
import { auth } from '@/store/auth.store';
import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import Minimal from '@/layouts/minimal';
import Default from '@/layouts/default';

@Component({
    components: {
        Minimal,
        Default
    },
    name: 'app',
})
export default class AppView extends Vue {
    // --------------------------------------------------------------------------
    // Fields
    // --------------------------------------------------------------------------
    private layout: string | null = null;
    public theme: 'light' | 'dark' = 'light';

    // --------------------------------------------------------------------------
    // Constructor
    // --------------------------------------------------------------------------

    constructor() {
        super();
    }

    // --------------------------------------------------------------------------
    // Accessors
    // --------------------------------------------------------------------------

    public get disableAnimations() {
        return app.disableAnimations;
    }

    public static async loadLayout(route: Route) {
        const meta = route.meta as { layout?: string } | undefined;

        // Lookup the layout property defined on the route.
        // Fallback to 'default' to load the Default layout otherwise.
        const { layout = 'minimal' } = meta ?? {};

        // Return tag to render: the layout's tag name or plain div as a fallback.
        return layout;
    }

    // --------------------------------------------------------------------------
    // Methods
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    // Event Handlers
    // --------------------------------------------------------------------------
    private async onRouterBeforeResolve(
        to: Route,
        from: Route,
        next: NavigationGuardNext,
    ) {
        // Clear existing layout.
        this.layout = 'div';

        // Update UI immediately to show loader. May not be visible if the
        // Layout was previously cached and is able to load quickly.
        this.$forceUpdate();

        // Load layout. Value will come from cache after first load of a Layout.
        this.layout = await AppView.loadLayout(to);

        next();
    }

    // --------------------------------------------------------------------------
    // Lifecycle Hooks
    // --------------------------------------------------------------------------
    private async mounted() {
        const { $route, $router } = this;

        // Expose services and stores in dev mode.
        if (IS_DEV) {
            (window as unknown as { appService: unknown }).appService = appService;
            (window as unknown as { authService: unknown }).authService = authService;
            (window as unknown as { app: unknown }).app = app;
            (window as unknown as { auth: unknown }).auth = auth;
        }

        // Update Layout on subsequent navigation events.
        $router.beforeResolve((to, from, next) => this.onRouterBeforeResolve(to, from, next));

        // Load initial Layout.
        this.layout = await AppView.loadLayout($route);
    }
}
