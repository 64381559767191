import env from '@/config/env';
import { GENDERS, PHONE_TYPES } from '@/data';
import { AssignmentResponse, EmrCancelAppointmentResult, FileUploadResponse, FormAssignment_v2, Gender, Patient, PatientCalendar, PatientDocument, PatientForm, PatientPractitioner, PatientProfile, S3Signature } from '@/entities';
import { Country } from '@/entities/country';
import { Phone } from '@/entities/phone';
import { Province } from '@/entities/province';
import { EndpointResponse, Lookup, formatDate } from '@/modules/core';
import { appLog } from '@/modules/core/app-logger';
import { ContentType } from '@/modules/core/unlocked-content';
import { app } from '@/store/app.store';
import { auth } from '@/store/auth.store';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { countReset } from 'console';
import { BaseService } from './_base';



// ----------------------------------------------------------------------------
// Module Vars
// ----------------------------------------------------------------------------
const { APP: APP_API_URL } = env.api;
const { EMR_HOST, EMR_DOCUMENT_CHART_URL: EMR_DOCUMENT_HOST, EMR_DOCUMENT_FILE_URL, S3_BUCKET_URL } = env.settings;
const COUNTIRES_FAILURE_MSGID = 'service.app.countriesFailure';
const ACCESS_CODE_FAILURE_MSGID = 'service.app.accessCodeFailure';
const CANCEL_APPOINTMENT_FAILURE_MSGID = 'service.app.cancelAppointmentFailure';
const FULL_ACCESS = 'Full Access';

const EMR_OPTIONS = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const LOADING_DEBOUNCE_MS = 1000;

export enum SmartFormsStatus {
  Submitted = 'SF-100',
  NotStarted = 'SF-101',
  Started = 'SF-102',
}

export enum AccessCode {
  FullAccess = 'AC-100',
  LimitedAccess = 'AC-101',
}

enum DocumentType {
  Document = 'Document',
  TreatmentPlan = 'Treatment Plan'
}

type S3SignatureKeysType = Array<keyof FileUploadResponse['signature']>;


// ----------------------------------------------------------------------------
// Module Types
// ----------------------------------------------------------------------------
class AppService extends BaseService {
  // --------------------------------------------------------------------------
  // Fields
  // --------------------------------------------------------------------------
  private isLoadingTimer?: NodeJS.Timeout;

  // --------------------------------------------------------------------------
  // Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super({
      baseURL: APP_API_URL,
    });
  }



  // --------------------------------------------------------------------------
  // Methods
  // --------------------------------------------------------------------------

  public async getCountries() {
    // TODO: determine response schema
    let result: EndpointResponse<Country[]>;

    try {
      const { data } = await this.api.get<Country[]>('utilities/countries');

      result = {
        data,
        success: true
      };
    }
    catch (error) {
      result = {
        success: false,
        localeMessageId: COUNTIRES_FAILURE_MSGID,
      };
    }

    return result;
  }

  public async getGenders(): Promise<Gender[]> {
    return GENDERS;
  }

  public async getPhoneTypes(): Promise<Phone[]> {
    return PHONE_TYPES;
  }

  public async getProvinces(country?: number) {
    // TODO: determine response schema
    let result: EndpointResponse<Province[]>;

    try {
      const { data } = await this.api.get<Province[]>('utilities/provinces', {
        params: country !== undefined ? { cid: country } : {}
      });

      result = {
        data,
        success: true
      };
    }
    catch (error) {
      result = {
        success: false,
        localeMessageId: COUNTIRES_FAILURE_MSGID,
      };
    }

    return result;
  }


  public async uploadDocument(fileDetails: FormData, file: File) {

    let result: EndpointResponse<FileUploadResponse>;
    try {
      // First request stores file data (name etc) in database
      // and receives a signature to needed store actual file to S3 bucket
      const { data: { signature } } = await this.api.post<FileUploadResponse>(`/document/upload`, fileDetails);
      const bodyFormData = new FormData();

      // Configure form fields required by AWS to store file in s3 bucket
      (Object.keys(signature) as S3SignatureKeysType).forEach((key) => {
        bodyFormData.append(key, signature[key]);
      });
      bodyFormData.append('file', file);

      // Store file in S3 bucket
      await this.api.post<void>(S3_BUCKET_URL, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      result = { success: true };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async getPractitioners(patientIds: string[]) {
    let result: EndpointResponse<PatientPractitioner[]>;

    const params = {
      patients: JSON.stringify(patientIds)
    };

    try {
      const { data } = await this.api.get<PatientPractitioner[]>('patients/practitioners', { params });

      result = { data, success: true };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }
  public async applyAccessCode(code: string) {
    // TODO: determine response schema
    let result: EndpointResponse<unknown>;

    try {
      const { data } = await this.api.post<unknown>('accesscode', { accessCode: code });

      result = {
        data,
        success: true
      };
    } catch (error) {
      result = {
        success: false,
        localeMessageId: ACCESS_CODE_FAILURE_MSGID
      };
    }

    return result;
  }

  /**
   * Cancel appointment in EMR host.
   * Note: This is not a RESTful endpoint.
   * @param id Appointment id.
   * @param reason Reason for cancelling.
   */
  public async cancelAppointment(eventId: string, reason: string) {
    // TODO: determine response schema
    let result: EndpointResponse<unknown | undefined>;
    const options = { ...EMR_OPTIONS };
    const body = new URLSearchParams();

    const payload = {
      f_id: 'Schedule/Cancellation/Post',
      info: {
        package: {
          eventId,
          reason
        }
      },
    };

    body.append('JSON', JSON.stringify(payload));

    try {
      const { data } = await Axios.post<EmrCancelAppointmentResult>(
        `${EMR_HOST}/AJAXSS.php`,
        body.toString(),
        options
      );

      // Failure scenario.
      if ((data.info.aErrors !== undefined && data.info.aErrors !== null && data.info.aErrors.length > 0) ||
          (data.info.Errors !== undefined && data.info.Errors !== null && data.info.Errors.length > 0)) {
        // Failure message provided by the EMR.
        const message = data.info.aErrors?.[0].ClientMsg.message ?? '';

        // We don't show the user messages from the EMR (not localized) but
        // we will log it for debugging purposes.
        appLog(message, false);

        result = {
          success: false,
          localeMessageId: CANCEL_APPOINTMENT_FAILURE_MSGID
        };
      }
      // Success scenario.
      else {
        result = { success: true };
      }
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async getPatients() {
    let result: EndpointResponse<Patient[]>;

    try {
      let { data } = await this.api.get<Patient[]>('patients');

      data = data.map(o => {
        const accessType = (o.accessType === FULL_ACCESS)
          ? AccessCode.FullAccess
          : AccessCode.LimitedAccess;

        return {
          id: o.id,
          firstName: o.firstName,
          lastName: o.lastName,
          accessType,
          jsonEncodedData: o.jsonEncodedData
        };
      });

      result = { data, success: true };
    }
    catch (error) {
      result = { data: [], success: false };
    }

    return result;
  }



  public async getCalendar(patientId: string) {
    let result: EndpointResponse<PatientCalendar>;

    try {
      const { data } = await this.api.get<PatientCalendar>(`/patients/${patientId}/appointments`);

      result = { data, success: true };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async getDocuments(patientId: string) {
    let result: EndpointResponse<PatientDocument[]>;

    try {
      const { data } = await this.api.get<PatientDocument[]>(`/patients/${patientId}/documents`);

      result = { data, success: true };
    }
    catch (error) {
      result = { data: undefined, success: false };
    }

    return result;
  }

  public async getProfile(patientId: string) {
    let result: EndpointResponse<PatientProfile>;

    try {
      const { data } = await this.api.get(`/patients/${patientId}/profile`);



      // tslint:disable-next-line: no-unsafe-any
      result = { data: data.data, success: true };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async getForms(patientId: string) {
    const FORMAT = 'X';
    const latestVersion = 2;
    let result: EndpointResponse<PatientForm[]>;

    try {
      let { data } = await this.api.get<PatientForm[]>(`patients/${patientId}/smartforms`);


      // TODO refactor this block of code
      data = data.map(form => {

        let status = '';
        const submitted = (form?.dSubmitted ?? 0);
        const started = (form?.dStarted ?? 0);

        if (submitted !== 0) {
          status = SmartFormsStatus.Submitted;
        } else if (started !== 0) {
          status = SmartFormsStatus.Started;
        } else {
          status = SmartFormsStatus.NotStarted;
        }

        if(form.iFormVersion === latestVersion){
          form.dStarted = Number(form.dStarted);
          form.dModified = Number(formatDate(form.dModified, FORMAT));
          form.dSubmitted = Number(formatDate(form.dSubmitted, FORMAT));
        }

        form.status = status;
        form.iConsolidatedID = [form.iFormVersion,form.iAssignmentID].join('-');

        return form;
      });


      result = { data, success: true };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async updateProfile(profile: PatientProfile) {
    const { iPatientID: patientId } = profile;
    let result: EndpointResponse<unknown>;

    try {
      const { data } = await this.api.put<unknown>(`patients/${patientId}/profile`, profile);

      result = { success: true };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async getDocumentUrl(documentId: number, patientId: string) {
    const BASE = 10;
    let result: EndpointResponse<unknown>;
    let url = '';

    const documents = app.documents[patientId];
    const document = documents?.find(doc => Number.parseInt(doc.itemId, BASE) === documentId);

    if (document === undefined) {
      result = {
        success: false,
      };

    } else {
      url = this.buildDocumentUrl(document);

      result = {
        success: true,
        data: url
      };
    }

    return result;

  }


  private buildDocumentUrl(document: PatientDocument) {
    let url = '';
    const { itemTypeName, itemId } = document;

    if (itemTypeName === DocumentType.TreatmentPlan) {
      url = `${EMR_HOST}${EMR_DOCUMENT_HOST}${itemId}`;
    } else if (itemTypeName === DocumentType.Document) {
      url = `${EMR_HOST}${EMR_DOCUMENT_FILE_URL}${itemId}`;
    } else {
      url = EMR_HOST;
    }



    return url;
  }

  private resetIsLoading() {
    this.isLoadingTimer = setTimeout(() => {
      app.setIsLoading(false);
    }, LOADING_DEBOUNCE_MS);
  }

  public async getForm_smartforms2(assignmentId: string, patientId: string) {
    let result: EndpointResponse<FormAssignment_v2>;

    try {
      const { data } = await this.api.get<FormAssignment_v2>(`patients/${patientId}/smartforms/assignment/${assignmentId}`);
      result = { success: true, data };

    } catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async submitAssignmentResponse(patientId: string, assignmentId: string, response: AssignmentResponse) {
    let result: EndpointResponse<unknown>;

    try {
      const { data } = await this.api.post<FormAssignment_v2>(`patients/${patientId}/smartforms/assignment/${assignmentId}`, response);
      result = { success: true };

    } catch (error) {
      result = { success: false };
    }

    return result;
  }

  public async updateAssignmentResponse(patientId: string, assignmentId: string, response: AssignmentResponse) {
    let result: EndpointResponse<unknown>;

    try {
      const { data } = await this.api.post<FormAssignment_v2>(`patients/${patientId}/smartforms/assignment/${assignmentId}`, response);
      result = { success: true };

    } catch (error) {
      result = { success: false };
    }

    return result;
  }

  // --------------------------------------------------------------------------
  // Event Handlers
  // --------------------------------------------------------------------------
  protected onRequest(config: AxiosRequestConfig) {
    const { isLoadingTimer } = this;
    const { common } = config.headers as { common: Lookup<string | undefined> };

    if (config.url !== S3_BUCKET_URL) {
      // Add authentication token to requests.
      common['auth-token'] = auth.token;
    }

    // Clear existing background loader stop timer to reduce
    // filcker with quickly jumping between enable/disabled states.
    if (isLoadingTimer !== undefined) {
      clearTimeout(isLoadingTimer);
    }

    // Update background loader flag.
    app.setIsLoading(true);
  }

  protected onRequestError(error: unknown) {
    this.resetIsLoading();
  }

  protected onResponse(response: AxiosResponse) {
    this.resetIsLoading();
  }

  protected onResponseError(error: unknown) {
    this.resetIsLoading();
  }
}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const appService = new AppService();

export {
  appService,
};

