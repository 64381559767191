/* tslint:disable:no-console */

import { register } from 'register-service-worker';

const isDev = process.env.STAGE !== 'prod';

register(`${process.env.BASE_URL}service-worker.js`, {
  ready(registration) {
    if (isDev) {
      console.log('Service worker is active. For more details, visit https://goo.gl/AFskqB');
    }
  },
  registered(registration) {
    if (isDev) {
      console.log('Service worker has been registered.');
    }
  },
  cached(registration) {
    if (isDev) {
      console.log('Content has been cached for offline use.');
    }
  },
  updatefound(registration) {
    if (isDev) {
      console.log('New content is downloading.');
    }
  },
  updated(registration) {
    if (isDev) {
      console.log('New content is available; please refresh.');
    }
    window.location.reload();
  },
  offline() {
    if (isDev) {
      console.log('No internet connection found. App is running in offline mode.');
    }
  },
  error(error) {
    if (isDev) {
      console.error('Error during service worker registration:', error);
    }
  },
});
