import Vue from 'vue';
import { Layout } from '@/modules/core';
import { auth } from '@/store/auth.store';
import Router, { NavigationGuardNext, RawLocation, Route } from 'vue-router';

// tslint:disable-next-line: no-unbound-method
const navigationPush = Router.prototype.push;
// Overriding the default push method to catch duplicated navigation errors
Router.prototype.push = async function push(location: RawLocation):Promise<Route> {
  let route: Route;

  route = await navigationPush.call<Router, [RawLocation], Promise<Route>>(this, location)
    .catch<Route>((error: Error) => {

      if (error.name !== NavigationFailureType.Duplicated) {
        throw error;
      }

      return this.currentRoute;
    });

    return route;
};

Vue.use(Router);

export enum NavigationFailureType {
  Duplicated = 'NavigationDuplicated'
}

export enum Page {
  Login = 'login',
  LoginFrame = 'login-frame',
  Register = 'register',
  Redeem = 'redeem',
  RedeemWithCode = 'redeem/',
  Profiles = 'profiles',
  Home = '/',
  ProfileDetail = 'profile-detail',
  PasswordRecovery = 'password-recovery',
  PasswordRecoveryToken = 'password-recovery-token',
  PageNotFound = 'not-found',
  Documents = 'documents',
  DocumentDetail = 'document-detail',
  Appointments = 'appointments',
  SmartForms = 'forms',
  Account = 'account',
  FormDetail = 'form-detail',
}

type Error = {
  code: string;
  name: string;
};

type RouteMeta = {
  layout: Layout;
  guestOnly?: boolean;
  allCanAccess?: boolean;
};


function redirectIfAccessCodeOrRequiresLogin() {
  return async (to: Route, from: Route, next: NavigationGuardNext) => {
    const query = to.query;
    const accessCode = query?.accessCode as string | undefined;
    const redirectPath = query?.redirectPath as string | undefined;
    const isLoggedIn = await auth.isLoggedIn();

    if (!isLoggedIn) {
      next({ name: Page.Login, query });
    }

    if (accessCode !== undefined) {
      next({ name: Page.RedeemWithCode, params: { accessCode } });
    } else if (redirectPath !== undefined) {
      next({ path: redirectPath });
    } else {
      next();
    }
  };
}

function passwordResetRedirect() {
  return async (to: Route, from: Route, next: NavigationGuardNext) => {
    const isLoggedIn = await auth.isLoggedIn();
    const recoveryToken = to.query.recoveryToken as string | undefined;

    if (isLoggedIn) {
      next({ name: Page.Home });
    }
    else if (recoveryToken !== undefined) {
      next({ name: Page.PasswordRecoveryToken, query: { recoveryToken } });
    }
    else {
      next({ name: Page.PasswordRecovery });
    }
  };
}


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: Page.Home,
      beforeEnter: redirectIfAccessCodeOrRequiresLogin(),
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(/* webpackChunkName: "home" */
          '@/pages/home')
    },
    {
      path: '/profiles',
      name: Page.Profiles,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(/* webpackChunkName: "home" */
          '@/pages/profile-overview')
    },
    {
      path: '/register',
      name: Page.Register,
      meta: {
        layout: Layout.Minimal,
        guestOnly: true
      },
      component: () =>
        import(
          '@/pages/register'),
    },
    {
      path: '/login',
      name: Page.Login,
      meta: {
        layout: Layout.Minimal,
        guestOnly: true
      },
      component: () =>
        import(
          '@/pages/login'),
    },
    {
      path: '/login-frame',
      name: Page.LoginFrame,
      meta: {
        layout: Layout.Minimal,
        guestOnly: true
      },
      component: () =>
        import(
          '@/pages/login-frame'),
    },
    {
      path: '/redeem/',
      name: Page.Redeem,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/redeem'),
    },
    {
      path: '/redeem/:accessCode',
      name: Page.RedeemWithCode,
      meta: {
        layout: Layout.Minimal,
        allCanAccess: true
      },
      component: () =>
        import(
          '@/pages/redeem-with-code'),
    },
    {

      path: '/profile-detail/:id',
      name: Page.ProfileDetail,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/profile-details'),
    },
    {
      path: '/documents',
      name: 'documents',
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/documents'),
    },
    {
      path: '/password-recovery',
      name: Page.PasswordRecovery,
      meta: {
        layout: Layout.Minimal,
        guestOnly: true

      },
      component: () =>
        import(
          '@/pages/password-recovery'),
    },
    {
      path: '/password-recovery',
      name: Page.PasswordRecoveryToken,
      meta: {
        layout: Layout.Minimal,
        guestOnly: true
      },
      component: () =>
        import(
          '@/pages/password-recovery'),
    },
    {
      path: '/document/:documentId/:patientId',
      name: Page.DocumentDetail,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/document-detail'),
    },
    {
      path: '/forms',
      name: Page.SmartForms,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/forms'),
    },
    {
      path: '/form-detail/:id/:patientId/:assignmentID/:action',
      name: Page.FormDetail,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/form-detail'),
    },
    {
      path: '/appointments',
      name: Page.Appointments,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/appointments'),
    },
    {
      path: '/account',
      name: Page.Account,
      meta: {
        layout: Layout.Default,
      },
      component: () =>
        import(
          '@/pages/account'),
    },
    {
      path: '/reset',
      meta: {
        guestOnly: true
      },
      beforeEnter: passwordResetRedirect(),

    },
    {
      path: '*',
      name: Page.PageNotFound,
      meta: {
        layout: Layout.Minimal,
        allCanAccess: true
      },
      component: () => import('@/pages/404')
    },
  ],
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  const isLoggedIn = await auth.isLoggedIn();
  const meta = to.meta as RouteMeta;
  const query = to.query;

  // For routes accessible by both Authenicated and Non-Authenticated users
  if (meta?.allCanAccess !== undefined && meta?.allCanAccess) {
    next();
  }

  else if (meta?.guestOnly !== undefined && meta.guestOnly && isLoggedIn) {
    next({
      name: Page.Home,
      query
    });
  }

  // Guarded routes don't have guestOnly so it will be undefined
  // Explicit check for undefined ensures that it was a guarded route
  else if (meta?.guestOnly === undefined && !isLoggedIn) {
    next({
      name: Page.Login,
      query: {
        redirectPath: to.fullPath
      }
    });
  }

  else {
    next();
  }
});

export default router;
