import { User } from '@/entities';
import { appLog } from '@/modules/core/app-logger';
import { auth } from '@/store/auth.store';
import router from '@/config/router';
import store from '@/store';
import _Vue from 'vue';
import rg4js from 'raygun4js';

function setUser (user?: User): void {
    rg4js('setUser', {
        identifier: user?.userId ?? '',
        isAnonymous: user !== undefined ? false : true,
        email: '',
        firstName: user?.firstName ?? '',
        fullName: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`
    });
}

interface RaygunPluginOptions {
    apiKey: string;
}

function RaygunPlugin(vue: typeof _Vue, options?: RaygunPluginOptions): void {
    const { apiKey } = options ?? { apiKey: '' };

    if (apiKey === '') {
        appLog('Raygun apiKey is missing, skipping install step.', true);

        return;
    }

    rg4js('apiKey', apiKey);
    rg4js('enableCrashReporting', true);
    rg4js('enablePulse', true);

    router.afterEach((to, from) => {
        rg4js('trackEvent', {
          type: 'pageView',
          path: to.path
        });
    });

    setUser(auth.user);
    store.watch<User|undefined>(() => auth.user, (value) => {
        setUser(value);
    });

    vue.config.errorHandler = function(err, vm, info) {
        rg4js('send', {
            error: err,
            customData: [{ info }]
        });
    };

}

export {
    RaygunPlugin as default,
    RaygunPlugin,
    RaygunPluginOptions
};
