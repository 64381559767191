import { PhoneType } from '@/modules/core/phone-type';
// The name proprty referces the path within the locale files
export const PHONE_TYPES = [
    {
        id: PhoneType.Mobile,
        name: 'common.phoneType.mobile'
    },
    {
        id: PhoneType.Home,
        name: 'common.phoneType.home'
    }
];
