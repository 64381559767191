module.exports = {
  purge: {
    content: ['./src/**/*.html', './src/**/*.vue', './src/**/*.jsx'],
    // These options are passed through directly to PurgeCSS
    options: {
      whitelist: [],
    },
  },
  theme: {
    screens: {
      default: { min: '0px ' },
      mobile: { min: '321px' },
      tablet: { min: '768px' },
      desktop: { min: '1280px' },
    },
    /**
     * Color values are defined in /src/theme/colors.scss.
     * Color names should be sematic in order to provide
     * contextual alignment when thinking about themes.
     * E.g. primary-text-color (semantic) vs black-1 (literal)
     */
    colors: {
      inherit: 'inherit',
      transparent: 'transparent',
      typography: {
        primary: 'var(--typography-primary)',
        secondary: 'var(--typography-secondary)',
        error: 'var(--typography-error)',
      },
      app: {
        background: 'var(--app-background)',
        'background-2': 'var(--app-background-2)',
        success: 'var(--app-success)',
        border: 'var(--app-border)',
        'status-danger': 'var(--danger)',
        banner: 'var(--banner)'
      },
      card: {
        background: 'var(--card-background)',
        border: 'var(--card-border)'
      },
      navbar: {
        primary: 'var(--navbar-primary)'
      },
      sidemenu: {
        border: 'var(--sidemenu-border)',
      },
      dashboard: {
        'profile-border': 'var(--dashboard-profile-border)',
        'document-border': 'var(--dashboard-document-border)',
        'smart-forms-border': 'var(--dashboard-smart-form-border)',
        'appointment-border': 'var(--dashboard-appointment-border)',
        'item-hover': 'var(--dashboard-item-hover)'
      },
      button: {
        'profile-edit-hover': 'var(--profile-edit)',
        'success': 'var(--button-success)',
        'success-highlighted': 'var(button-success-highlighted)',
        'disabled': 'var(--button-disabled)',
        'chat': 'var(--button-chat)',
        'view-more': 'var(--button-view-more)',
        'view-more-highlighted': 'var(--button-view-more-highlighted)',
        'close-highlighted': 'var(--button-close-highlighted)',
        'smartforms-modify': 'var(--button-modify)',
        'smartforms-modify-highlighted': 'var(--button-smartforms-modify-highlighted)',
        'smartforms-view-graph': 'var(--button-smartforms-view-graph)',
        'smartforms-view-graph-highlighted': 'var(--button-smartforms-view-graph-highlighted)',
      },

      form: {
        'form-field-focused': 'var(--form-field-focused)'
      }
    },
    extend: {
      height: {
        '3/4': '75%',
        '72': '20rem'
      },
      minHeight: {
        '1/2-screen': '50vh',
        '3/4-screen': '75vh',
      },
      maxWidth: {
        mobile: '768px',
        tablet: '1024px',
        desktop: '1280px',
        card: '28rem',
        'card-lg': '38rem',
        'half': '50%'
      },
      scale: {
        '200': '2'
      },
      inset: {
        '4': '1rem',
        '16': '4rem',
        '20': '5rem'
      }
    },
  },
  variants: {},
  plugins: [],
};
