import { User, UserRegistrationInfo } from '@/entities';
import { Cache, getMultiParamModule } from '@/modules/core';
import { MultiParamAction, MultiParamMutation } from '@/modules/core/vuex';
import { AuthErrorCode, authService } from '@/services/auth.service';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from './index';

const KEY_USER = 'user';
const KEY_TOKEN = 'token';
const KEY_COOKIE = 'cookie';

@Module({
  name: 'auth',
  dynamic: true,
  namespaced: true,
  store
})
class AuthStore extends VuexModule {
  // ------------------------------------------------------------------------
  // Fields
  // ------------------------------------------------------------------------
  private static readonly cache = new Cache('auth-store');
  private _user?: User = AuthStore.cache.get(KEY_USER);
  private _token?: string = AuthStore.cache.get(KEY_TOKEN);
  private _cookie?: string = AuthStore.cache.get(KEY_COOKIE);

  // ------------------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------------------
  public get user() {
    return this._user;
  }

  public get token() {
    return this._token;
  }

  public get cookie() {
    return this._cookie;
  }

  public get policyAgreement() {
    return this._user?.iPrivacyPolicyAgreed;
  }


  // ------------------------------------------------------------------------
  // Actions
  // ------------------------------------------------------------------------
  @MultiParamAction()
  public setPrivacyPolicy(value: boolean) {
    const TRUE = 1;
    const FALSE = 0;

    const user = this._user as User;
    user.iPrivacyPolicyAgreed = value ? TRUE : FALSE;
    this.setUser(user);
  }

  @MultiParamAction()
  public getErrorCode() {
    return AuthErrorCode;
  }

  @MultiParamAction()
  public async login(email: string, password: string) {
    const { success, data } = await authService.login(email, password);

    if (success && data !== undefined) {
      this.setUser(data.userData);
      this.setToken(data.authToken);
    }


    return {
      success
    };
  }

  @MultiParamAction()
  public async logout() {
    const { success } = await authService.logout();

    return {
      success,
    };
  }

  @MultiParamAction()
  public async register(user: UserRegistrationInfo) {
    const { success, errorCode } = await authService.register(user);

    return { success, errorCode };
  }

  @MultiParamAction()
  public async resetPassword(email: string) {
    const { success, localeMessageId } = await authService.resetPassword(email);

    return {
      success,
      localeMessageId
    };
  }

  @MultiParamAction()
  public async completePasswordReset(
    newPassword: string,
    confirmNewPassword: string,
    recoveryToken: string
  ) {
    const { success, localeMessageId } = await authService.completePasswordReset(
      newPassword,
      confirmNewPassword,
      recoveryToken
    );

    return {
      success,
      localeMessageId
    };
  }

  @MultiParamAction()
  public async emrValidateSession(user: User) {
    const { success } = await authService.emrValidateSession(user);

    return success;
  }

  @MultiParamAction()
  public async isLoggedIn() {
    const { token, user } = this;
    let result = false;

    if (token !== undefined && user !== undefined) {
      result = true;
    }

    return result;

  }

  @MultiParamAction()
  public async changeEmail(currentEmail: string, newEmail: string) {
    const result = await authService.changeEmail(currentEmail, newEmail);

    if (result.success) {

      this.setUser({
        ...this.user,
        email: newEmail
      } as User);
    }

    return result;
  }

  @MultiParamAction()
  public async updatePolicyAgreement(oldPrivacyPolicyAgreement: boolean, newPrivacyPolicyAgreement: boolean) {
    const { success } = await authService.updatePolicyAgreement(oldPrivacyPolicyAgreement, newPrivacyPolicyAgreement);

    return success;
  }

  // ------------------------------------------------------------------------
  // Mutations
  // ------------------------------------------------------------------------
  @MultiParamMutation
  private setUser(value: User) {
    this._user = value;

    AuthStore.cache.set(KEY_USER, value);
  }

  @Mutation
  public clearUser() {

    AuthStore.cache.set(KEY_USER, undefined);
    AuthStore.cache.set(KEY_TOKEN, undefined);

    this._token = undefined;
    this._user = undefined;

  }

  @MultiParamMutation
  private setToken(value: string) {
    this._token = value;

    AuthStore.cache.set(KEY_TOKEN, value);
  }

  @MultiParamMutation
  private setCookie(value: string) {
    this._cookie = value;

    AuthStore.cache.set(KEY_COOKIE, value);
  }


}


// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------
const auth = getMultiParamModule(AuthStore, store);

export {
  auth
};

