import i18n from '@/config/i18n';
import moment from 'moment-timezone';
import env from '@/config/env';
import { app } from '@/store/app.store';
import { get } from 'http';
const { TIME_ZONE } = env.settings;

const DEFAULT_DATE_FORMAT = 'MMM Do, YYYY';
const EPOCH_DATE_FORMAT = 'x';


export enum Layout {
  Default = 'default',
  Minimal = 'minimal'
}

export enum ScreenSize {
  DESKTOP = 1280,
  MOBILE = 321,
  TABLET = 768,
}
/**
 *
 * @param date - date to format
 * @param dateFormat - desired date format
 */
export function formatDate(date: number | string | null, dateFormat = DEFAULT_DATE_FORMAT, timeZone = TIME_ZONE) {
  if (date === null) {
    return '';
  }

  return moment((date), (typeof date === 'string' ? dateFormat : EPOCH_DATE_FORMAT))
    .tz(timeZone)
    .locale(i18n.locale)
    .format(dateFormat);
}

export enum ToastType {
  Success = 'is-success',
  Failure = 'is-danger',
}

/**
 * Description - accepts a phone number and an optional extension
 * combines the phone number with the extension(if present), else it returns the given phone number
 * @param phoneNumber -The phone number
 * @param extension - optional extension
 */
export function formatPhoneNumber(phoneNumber: string, extension: number | null) {
  let formattedNumber = '';

  if (extension !== null && !isNaN(extension)) {
    formattedNumber = `${phoneNumber} Ext. ${extension}`;
  } else {
    formattedNumber = phoneNumber;
  }

  return formattedNumber;
}


export enum FormAction {
  View = 'View-Form',
  Fill = 'Fill',
  Chart = 'View-Chart'
}

export enum FormVersion {
  One = 1,
  Two = 2
}
