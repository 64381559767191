import { IS_DEV } from '@/config/env';

/**
 * Application logger.
 * @param data String or Object to log.
 * @param devOnly Production errors can be logged by passing false.
 * Otherwise errors are only logged in development mode.
 */
export function appLog(data: unknown, devOnly = true) {
  if (devOnly && IS_DEV || !devOnly) {
    // tslint:disable-next-line:no-console
    console.log(data);
  }
}
