import env from '@/config/env';
import { AuthSessionData, EmrAuthResult, User, UserRegistrationInfo } from '@/entities';
import { EndpointResponse, Lookup } from '@/modules/core';
import { appLog } from '@/modules/core/app-logger';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseService } from './_base';
import { auth } from '@/store/auth.store';

// ----------------------------------------------------------------------------
// Module Vars
// ----------------------------------------------------------------------------
const { APP: APP_API_URL } = env.api;
const { EMR_HOST } = env.settings;
const { BYPASS_EMR_LOGIN } = env.dev;
const LOGIN_FAILURE_MSGID = 'service.auth.loginFailure';
const PASSWORD_FAILURE_MSGID = 'service.auth.passwordResetFailure';
const REGISTER_ACCOUNT_ALREADY_EXIST = 'Account already exists';
const REGISTER_INVALID_ACCESS_CODE = 'Invalid access code';
const EMR_OPTIONS = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

enum AuthErrorCode {
  Default = '000',
  InvalidAccesCode = '100',
  AccountAlreadyExist = '200'
}

export enum UpdateAccountErrorCode {
  EmailAlreadyExist = 'UA000',
  UpdatePolicyFailed = 'UA001',
}

const EMR_AUTH_URL = `${EMR_HOST}/AJAXSS.php`;

// ----------------------------------------------------------------------------
// Module Types
// ----------------------------------------------------------------------------
class AuthService extends BaseService {
  // --------------------------------------------------------------------------
  // Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super({
      withCredentials: true,
      baseURL: APP_API_URL,
    });
  }

  // --------------------------------------------------------------------------
  // Methods
  // --------------------------------------------------------------------------
  public async login(email: string, password: string) {
    let result: EndpointResponse<AuthSessionData | undefined>;

    try {
      const { data } = await this.api.post<AuthSessionData>('login', { email, password });
      const { userData } = data;

      result = {
        success: true, data
      };
    }
    catch (error) {
      result = {
        success: false,
        localeMessageId: LOGIN_FAILURE_MSGID,
      };
    }

    return result;
  }

  public async logout() {
    // TODO: PP logout
    const portalSuccess = true;

    const emrSuccess = await this.emrLogout();

    return {
      success: emrSuccess && portalSuccess
    };
  }

  public async register(user: UserRegistrationInfo) {
    // TODO: determine response schema
    let result: EndpointResponse<unknown>;

    try {
      const { data } = await this.api.post<{}>('register', user);

      result = { success: true, data };

    }
    catch (error) {
      let errorCode = '';

      // tslint:disable-next-line: no-unsafe-any
      if (error.response.data.errorMessage === REGISTER_ACCOUNT_ALREADY_EXIST) {
        errorCode = AuthErrorCode.AccountAlreadyExist;
      }
      // tslint:disable-next-line: no-unsafe-any
      else if (error.response.data.errorMessage === REGISTER_INVALID_ACCESS_CODE) {
        errorCode = AuthErrorCode.InvalidAccesCode;
      }
      else {
        errorCode = AuthErrorCode.Default;
      }

      result = {
        success: false,
        data: undefined,
        errorCode
      };
    }

    return result;
  }

  public async resetPassword(email: string) {
    // TODO: determine response schema
    let result: EndpointResponse<unknown>;

    try {
      const { data } = await this.api.post<unknown>('password/recoveryemail', { email });

      result = { success: true, data };
    }
    catch (error) {
      result = {
        success: false,
        data: undefined,
        localeMessageId: PASSWORD_FAILURE_MSGID
      };
    }

    return result;
  }

  public async completePasswordReset(
    newPassword: string,
    confirmNewPassword: string,
    recoveryToken: string
  ) {
    // TODO: determine response schema
    let result: EndpointResponse<unknown>;
    const requestData = { newPassword, confirmNewPassword, recoveryToken };

    try {
      const { data } = await this.api.post<unknown>('password/reset', requestData);

      result = { success: true, data };
    }
    catch (error) {
      result = {
        success: false,
        data: undefined,
        localeMessageId: PASSWORD_FAILURE_MSGID
      };
    }

    return result;
  }

  public async changeEmail(currentEmail: string, newEmail: string) {
    let result: EndpointResponse<unknown>;

    const payload = {
      newUserEmail: newEmail,
      oldUserEmail: currentEmail
    };

    try {
      await this.api.post<unknown>(`users/account`, payload);

      result = { success: true };
    }
    catch (error) {
      let errorCode = '';
      const emailAlreadyExist = 'Email is already taken, please use another one.';

      // tslint:disable-next-line: no-unsafe-any
      if (error.response.data.errorMessage === emailAlreadyExist) {
        errorCode = UpdateAccountErrorCode.EmailAlreadyExist;
      }

      result = { success: false, errorCode };
    }

    return result;
  }


  public async updatePolicyAgreement(oldPrivacyPolicyAgreement: boolean, newPrivacyPolicyAgreement: boolean) {
    let result: EndpointResponse<unknown>;

    const payload = {
      oldPrivacyPolicyAgreement,
      newPrivacyPolicyAgreement
    };

    try {
     const {data}= await this.api.post<unknown>(`users/account`, payload);

      result = { success: true };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  private async emrLogin(user: User) {
    // TODO: determine response schema
    let result: EndpointResponse<unknown>;
    const options = { ...EMR_OPTIONS };
    const body = new URLSearchParams();

    const payload = {
      f_id: 'Users/AuthenticateWithoutPassword',
      info: {
        package: user,
      },
    };

    body.append('JSON', JSON.stringify(payload));

    try {
      const { data } = await Axios.post<EmrAuthResult>(
        EMR_AUTH_URL,
        body.toString(),
        options
      );

      result = { data, success: data?.info?.bStatus ?? false };
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  private async emrLogout() {
    const options = { ...EMR_OPTIONS };
    const body = new URLSearchParams();

    const payload = {
      f_id: 'Users/Logout',
      info: {},
    };

    body.append('JSON', JSON.stringify(payload));

    const result = await this.api.post<EmrAuthResult>(EMR_AUTH_URL, body.toString(), options);

    return result.data.info?.bStatus ?? false;
  }


  /**
   * Keep the session alive within the separate EMR app.
   */
  public async emrValidateSession(user: User) {
    let result: EndpointResponse<unknown>;
    const body = new URLSearchParams();

    if (BYPASS_EMR_LOGIN) {
      return { success: true };
    }

    const payload = {
      f_id: 'Users/ValidateSession',
      info: {
        userId: user.userId
      },
    };

    body.append('JSON', JSON.stringify(payload));

    try {
      const { data } = await Axios.post<EmrAuthResult>(EMR_AUTH_URL, body.toString(), EMR_OPTIONS);

      if (data?.info?.bStatus === false) {
        // Session is invalid so we will re-authenticate.
        const { success } = await this.emrLogin(user);

        result = { success };
      }
      else {
        result = { success: true };
      }
    }
    catch (error) {
      result = { success: false };
    }

    return result;
  }

  // --------------------------------------------------------------------------
  // Event Handlers
  // --------------------------------------------------------------------------
  protected onRequest(config: AxiosRequestConfig) {
    const { common } = config.headers as { common: Lookup<string | undefined> };
    const isEMR = config.url === EMR_AUTH_URL ? true : false;

    if (!isEMR) {
      // Add authentication token to requests.
      common['auth-token'] = auth.token;
    }

  }

  protected onRequestError(error: unknown) {
    appLog(error);
  }

  protected onResponse(response: AxiosResponse) {
    // TODO:
  }

  protected onResponseError(error: unknown) {
    appLog(error);
  }
}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const authService = new AuthService();

export {
  authService,
  AuthErrorCode
};

