// ----------------------------------------------------------------------------
// Module Vars
// ----------------------------------------------------------------------------
const IS_DEV = process.env.STAGE !== 'prod';

const config = {
  // Add non-environment specific defaults here
  theme: 'default',
  api: {
    APP: process.env.PATIENT_PORTAL_API_URL!
  },
  keys: {
    MASTER_STORAGE_ID: 'patient.outsmart.com',
    USER_SETTINGS_ID: 'user-settings',
    RECAPTCHA: process.env.RECAPTCHA_KEY!,
    RAYGUN: process.env.RAYGUN_KEY!
  },
  brand: {
    logo: '/assets/img/brand-logo.png',
    HELP_VIDEO_URL: 'https://player.vimeo.com/video/431809321?autoplay=1',
    PRIVACY_URL: 'https://www.outsmartemr.com/privacy-policy/',
  },
  icons: {
    PATIENT_DETAILS: '/assets/img/patient-details.svg',
    DOCUMENTS: '/assets/img/documents.svg',
    SMART_FORMS: '/assets/img/smartforms.svg',
    USER_ICON: '/assets/img/user-icon.svg',
    LOGOUT: '/assets/img/logout.svg',
    APPOINTMENTS: '/assets/img/appointments.svg',
    DASHBOARD: '/assets/img/back-to-dashboard.svg',
    PRIVACY_POLICY: '/assets/img/privacy-policy.svg',
    ACCESS_CODE: '/assets/img/accessCodeIcon.png',
  },
  settings: {
    API_TIMEOUT: 15000,
    ENABLE_MESSAGING: false,
    TIME_ZONE: 'America/Edmonton',
    S3_BUCKET_URL: process.env.UPLOAD_S3_BUCKET_URL!,
    EMR_HOST: process.env.OUTSMART_EMR_HOST!,
    EMR_APP_HOST: process.env.OUTSMART_EMR_APP_HOST!,
    EMR_DOCUMENT_CHART_URL: '/SND/templates/practitioner/print_templates/chart_print.php?PP=1&iFormID=',
    EMR_DOCUMENT_FILE_URL: '/FileDisplay.php?bView=1&iFileID=',
    EMR_SMARTFORMS_VIEW_URL: '/SND/templates/common/smartforms/view-form.php?iFormID=',
    EMR_SMARTFORMS_CHART_URL: '/SND/templates/common/smartforms/view-chart.php?iFormID=',
    EMR_SMARTFORMS_FILL_URL: '/SND/templates/common/smartforms/fill-form.php?iFormID='
  },
  dev: {
    BYPASS_EMR_LOGIN: false,
  },
  featureToggle: {
    // Animations displayed when transitioning between pages
    ANIMATIONS_ENABLED: false,
    // Ability for patients to change their agreement to OutSmart's privacy policy
    PRIVACY_POLICY_ENABLED: false,
    // Blue popup on home page that lets the user know if changes occurred in forms, documents or appointments
    CHANGE_NOTIFICATION_ENABLED: false,
  }
};

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------
export {
  config as default,
  IS_DEV
};

